import React, { useEffect, useState } from 'react';
import {
  ArtworkStatus,
  ImpressionArtworkItemFragment,
  PaginatedArtworkFragment,
} from '@generated/codegen';
import Image from 'next/image';
import { ArtworkModalLink } from '@components/artwork/ArtworkModal';
import { StudioLink } from '@components/common/routing';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { useMounted } from '@lib/hooks/useMounted';
import { Badge } from '@components/ui/Badge';
import { useTailwindScreen } from '@lib/hooks/useTailwindScreen';
import { useRouter } from 'next/router';
import { CURRENCY } from '@shared/enums';
import { ArtworkPrice } from '@components/artwork/ArtworkView/ArtworkPrice';

type ColHeightMap = number[];

/**
 * Returns the index of the column with the smallest height.
 */
function findSmallestColIndex(colHeights: ColHeightMap) {
  let smallestIndex = 0;
  let smallestHeight = Infinity;
  colHeights.forEach((height, i) => {
    if (height < smallestHeight) {
      smallestHeight = height;
      smallestIndex = i;
    }
  });

  return smallestIndex;
}

const MasonryCard = React.memo(function MasonryCard({
  artwork,
  isLastInColumn,
  onLastItemInView,
  onArtworkSelect,
}: {
  artwork: PaginatedArtworkFragment;
  isLastInColumn: boolean;
  onLastItemInView?: () => void;
  onArtworkSelect: (artwork: ImpressionArtworkItemFragment) => void;
}) {
  const mounted = useMounted();
  const [imageLoading, setImageLoading] = useState(true);
  const image = artwork.images[0];
  const [lastItemRef, inView] = useInView({ rootMargin: '350px' });

  useEffect(() => {
    if (inView && onLastItemInView) {
      onLastItemInView();
    }
  }, [inView]);

  if (!image || !image.width || !image.height) {
    return null;
  }

  const src = image.servingUrl ? `${image.servingUrl}=s600` : image.url;
  if (!src || !artwork.studio) {
    return null;
  }

  return (
    <div className={`flex flex-col relative bg-white`}>
      <ArtworkModalLink
        studioPermalinkOrId={artwork.studio.permalinkOrId}
        artworkId={artwork.id}
      >
        <a
          className={classNames(
            'block text-gray-500 transition-colors cursor-pointer hover:text-gray-700 bg-gray-200 relative',
            { 'bg-gray-300 animate-pulse': imageLoading }
          )}
          title={artwork.title}
          ref={isLastInColumn ? lastItemRef : undefined}
          onClick={() => {
            onArtworkSelect(artwork);
          }}
        >
          {artwork.status === ArtworkStatus.Sold && (
            <Badge className="absolute z-10 bg-opacity-50 top-2 left-2">
              Sold
            </Badge>
          )}
          <Image
            src={src}
            width={image.width}
            height={image.height}
            alt={artwork.title}
            className="mb-1"
            layout="responsive"
            sizes="(min-width: 640px) 250px, (min-width:768px) 400px, (min-width:1536px) 350px, 300px"
            onLoadingComplete={() => {
              // prevent "Can't perform a React state update on an unmounted component" warning
              if (mounted) {
                setImageLoading(false);
              }
            }}
          />
        </a>
      </ArtworkModalLink>

      <div
        className={`flex flex-col mt-2 ${
          imageLoading ? 'opacity-0' : 'opacity-100'
        } transition-opacity duration-200 ease-in`}
      >
        {artwork.studio?.name && (
          <StudioLink permalinkOrId={artwork.studio?.permalinkOrId}>
            <a className="block truncate hover:underline">
              {artwork.studio?.name}
            </a>
          </StudioLink>
        )}
        <a className="truncate text-sm text-gray-600">{artwork.title}</a>
        {artwork.status === ArtworkStatus.ForSale && artwork.formattedPrice && (
          <>
            {ArtworkPrice(
              artwork.basePrice ?? 0,
              artwork.bkhPrice ?? 0,
              artwork.currency === 'EUR' ? CURRENCY.EUR : CURRENCY.NOK
            )}
          </>
        )}
      </div>
    </div>
  );
});

function MasonryColumn({ children }: { children: React.ReactNode }) {
  return <div className="overflow-hidden">{children}</div>;
}

type NumCols = 2 | 3 | 4 | 5;

const gap = 16;

// https://janosh.dev/blog/react-hooks-masonry
function Masonry({
  items,
  numCols,
  className,
  useExperimentalReordering,
  ...props
}: {
  useExperimentalReordering: boolean;
  items: { height: number; width: number; children: React.ReactNode }[];
  numCols: NumCols;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) {
  const colsArray = [...Array(numCols)];
  const colsToRender: React.ReactNode[][] = colsArray.map(() => []);
  const colHeights: ColHeightMap = Array(numCols).fill(0);

  items.forEach((item, i) => {
    let colIndex = i % numCols;
    if (!useExperimentalReordering) {
      colsToRender[colIndex].push(item.children);
      return;
    }
    const ratio = item.width / item.height;
    const height = Math.round(item.height / ratio);
    const isFirstRow = numCols > i;
    // Skip reordering first row
    if (!isFirstRow) {
      colIndex = findSmallestColIndex(colHeights);
    }
    // Add the height of the item and the gap to the column heights.
    // It is very important we add the gap since the more elements we have,
    // the bigger the role the margins play when computing the actual height of the columns.
    colHeights[colIndex] += height + gap;
    colsToRender[colIndex].push(item.children);
  });

  return (
    <div
      role="list"
      className={classNames(className, 'grid grid-flow-col gap-4', {
        'grid-cols-2': numCols === 2,
        'grid-cols-3': numCols === 3,
        'grid-cols-4': numCols === 4,
        'grid-cols-5': numCols === 5,
      })}
      {...props}
    >
      {colsArray.map((_, index) => (
        <MasonryColumn key={index}>{colsToRender[index]}</MasonryColumn>
      ))}
    </div>
  );
}

const masonryListItemClassName = 'after:block after:h-4 after:bg-white';

const MasonryCardListItem = React.memo(function MasonryCardListItem({
  artwork,
  children,
  index,
  onArtworkImpression,
}: {
  index: number;
  artwork: PaginatedArtworkFragment;
  children: React.ReactNode;
  onArtworkImpression: (
    artwork: ImpressionArtworkItemFragment,
    index: number
  ) => void;
}) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-50% 0px',
  });
  useEffect(() => {
    if (inView) {
      onArtworkImpression(artwork, index);
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      role="listitem"
      className={classNames('relative z-10', masonryListItemClassName)}
    >
      {children}
    </div>
  );
});

const skeletonArtworks: Array<number> = [
  300, 400, 300, 200, 400, 600, 300, 450, 500, 300, 400,
];

function SkeletonMasonry({
  numCols,
  useExperimentalReordering,
}: {
  numCols: NumCols;
  useExperimentalReordering: boolean;
}) {
  return (
    <Masonry
      numCols={numCols}
      useExperimentalReordering={useExperimentalReordering}
      className="absolute inset-x-0 z-0 items-end bottom-4"
      items={skeletonArtworks.map((height, index) => {
        return {
          height,
          width: 100,
          children: (
            <div
              key={'skeleton-' + index}
              className={classNames(
                'bg-grey-100 opacity-50',
                masonryListItemClassName
              )}
              style={{ height }}
              aria-hidden
            />
          ),
        };
      })}
    />
  );
}

const MasonryArtworkList = function MasonryArtworkList({
  items,
  onLastItemInView,
  onArtworkImpression,
  onArtworkSelect,
  haveMorePages,
  loading,
}: {
  items: Array<PaginatedArtworkFragment>;
  onLastItemInView: () => void;
  onArtworkImpression: (
    artwork: ImpressionArtworkItemFragment,
    index: number
  ) => void;
  onArtworkSelect: (
    artwork: ImpressionArtworkItemFragment,
    index: number
  ) => void;
  haveMorePages: boolean;
  loading: boolean;
}) {
  const router = useRouter();
  const useExperimentalReordering = router.query.__reorder?.toString() === '1';
  const [numCols, setNumCols] = useState<NumCols>(2);
  const screen = useTailwindScreen();
  const itemsCount = items.length;

  useEffect(() => {
    switch (screen.currentScreen) {
      case null: {
        setNumCols(2);
        break;
      }
      case 'md':
      case 'lg': {
        setNumCols(3);
        break;
      }
      case 'xl':
      case '2xl': {
        setNumCols(4);
        break;
      }
      case '3xl': {
        setNumCols(5);
        break;
      }
    }
  }, [screen.currentScreen]);

  return (
    <div className="relative overflow-hidden bg-white">
      <Masonry
        data-testid="masonry"
        numCols={numCols}
        useExperimentalReordering={useExperimentalReordering}
        items={items.map((item, index) => {
          const isLastInColumn = index + 1 > itemsCount - numCols;
          return {
            height: item.height || 0,
            width: item.width || 0,
            children: (
              <MasonryCardListItem
                key={item.id}
                artwork={item}
                index={index}
                onArtworkImpression={onArtworkImpression}
              >
                <MasonryCard
                  artwork={item}
                  isLastInColumn={isLastInColumn}
                  onLastItemInView={onLastItemInView}
                  onArtworkSelect={(artwork) => {
                    onArtworkSelect(artwork, index);
                  }}
                />
              </MasonryCardListItem>
            ),
          };
        })}
      />
      {haveMorePages && loading && (
        <SkeletonMasonry
          numCols={numCols}
          useExperimentalReordering={useExperimentalReordering}
        />
      )}
    </div>
  );
};

export default MasonryArtworkList;
