import { AuthModal } from '@components/auth/AuthModal';
import { AtelierLink, AtelierLinkHref } from '@components/common/routing';
import { Modal } from '@components/ui/Modal';
import { useArtworkDetailsQuery } from '@generated/codegen';
import { ARTWORK_MODAL_QUERY_KEY } from '@lib/constants';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import React from 'react';
import dynamic from 'next/dynamic';
import { getArtworkPathname } from '@shared/routing-utils';
import { ArtworkHeaderActions } from './ArtworkView/ArtworkHeaderActions';

const ArtworkView = dynamic(import('./ArtworkView'));

export function ArtworkModal({
  artworkId,
  fullscreen,
}: {
  artworkId: string | undefined;
  fullscreen: boolean;
}) {
  const router = useRouter();
  const { data } = useArtworkDetailsQuery({
    variables: { id: artworkId || '' },
    skip: !artworkId,
    returnPartialData: true,
    fetchPolicy: 'cache-and-network',
  });

  const title = data?.artwork?.title;

  return (
    <>
      {title && <NextSeo title={title} />}
      {artworkId && fullscreen && (
        <>
          <ArtworkView artworkId={artworkId} scrollToTop showBackButton />
          <AuthModal />
        </>
      )}
      {!fullscreen && (
        <>
          <Modal
            data-testid="ArtworkModal"
            open={Boolean(artworkId)}
            size="lg"
            onClose={() => {
              const query = { ...router.query };
              delete query.artworkId;
              delete query.permalinkOrId;
              delete query[ARTWORK_MODAL_QUERY_KEY];
              router.push({ pathname: '/', query }, undefined, {
                shallow: true,
                scroll: false,
              });
            }}
          >
            {data?.artwork && (
              <Modal.HeaderActions>
                <ArtworkHeaderActions
                  artwork={data.artwork}
                  className="justify-end"
                />
              </Modal.HeaderActions>
            )}
            {artworkId && (
              <div className="text-left">
                <ArtworkView artworkId={artworkId} modal />
              </div>
            )}
            <AuthModal />
          </Modal>
        </>
      )}
    </>
  );
}

export function ArtworkModalLink({
  studioPermalinkOrId,
  artworkId,
  children,
}: {
  studioPermalinkOrId: string;
  artworkId: string;
  children: React.ReactNode;
}) {
  const router = useRouter();
  let scroll = false;
  let href: AtelierLinkHref;
  let as;
  // if start page, use href with artworkId to trigger modal. otherwise, go to the page
  const pathname = '/studio/[permalinkOrId]/a/[artworkId]';
  const query = {
    artworkId,
    permalinkOrId: studioPermalinkOrId,
  };
  if (router.pathname === '/') {
    href = {
      pathname: '/',
      query: {
        [ARTWORK_MODAL_QUERY_KEY]: '1',
        ...query,
        ...router.query,
      },
    };
    as = {
      pathname: getArtworkPathname({ id: artworkId, studioPermalinkOrId }),
      query: router.query,
    };
  } else {
    scroll = true;
    href = {
      pathname,
      query,
    };
  }
  return (
    <AtelierLink href={href} as={as} scroll={scroll} shallow>
      {children}
    </AtelierLink>
  );
}
