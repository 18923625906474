import { useWindowWidth } from '@react-hook/window-size';
import { useEffect } from 'react';
import screens from '../tailwind/screens.json';

// array of screens, sorted by highest first
const screensArray: Array<[screen: keyof typeof screens, width: number]> = [];

for (const [key, valueAsPx] of Object.entries(screens)) {
  const screen = key as keyof typeof screens;
  // const value = parseInt(valueAsPx.replace('px', ''));
  screensArray.push([screen, valueAsPx]);
}

screensArray.sort((a, b) => (a[1] < b[1] ? 1 : -1));

/**
 * Get the current breakpoint.
 */
export function useTailwindScreen() {
  const windowWidth = useWindowWidth();
  // trigger a resize on load, to make sure correct media query is set when SSR
  useEffect(() => {
    if (windowWidth === 0) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [windowWidth]);
  for (const [screen, width] of screensArray) {
    if (windowWidth > width) {
      return { currentScreen: screen };
    }
  }
  /**
   * Null means lower than sm
   */
  return { currentScreen: null };
}
