import classNames from 'classnames';
import React from 'react';

export type BadgeProps = {
  color?: 'gray' | 'red';
} & React.LabelHTMLAttributes<HTMLDivElement>;

export const Badge = ({
  className,
  style,
  color = 'gray',
  ...props
}: BadgeProps) => {
  return (
    <div
      className={classNames(
        className,
        'inline-flex items-center justify-center h-5 px-2 text-xs tracking-wider rounded-full',
        { 'bg-gray-200 text-gray-900': color === 'gray' },
        { 'bg-red-400 text-white': color === 'red' }
      )}
      style={{ minWidth: '1.25rem', ...style }}
      {...props}
    />
  );
};
